<template>
  <div v-if="editSpeaker" class="update-scribe-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(update)">
        <section class="mb-2">
          <label>Speaker Label</label>
          <validation-provider
            v-slot="validationContext"
            name="speaker_label"
            :rules="{ required: true }"
          >
            <b-form-input
              v-model="editSpeaker.speaker_label"
              :state="getValidationState(validationContext)"
              class="mb-1"
              placeholder="Speaker Name..."
            />

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
          <b-button variant="primary" type="submit">Update</b-button>
        </section>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>

export default {
  components: {},
  props: {
      speaker: {
          required: true,
          type: Object,
      },
  },
  data() {
    return {
        editSpeaker: null,
    };
  },
  mounted() {
    this.editSpeaker = { ...this.speaker }
  },
  methods: {
    async update() {
        this.$emit('update', this.editSpeaker)
    },
    getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
    },
  },
};
</script>

<style lang="scss">

</style>
