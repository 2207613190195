<template>
  <div v-if="validJson" class="json-summary">
    <!-- Object Support -->
    <div v-if="isObject(jsonSummary)" class="json-object">
      <!-- Loop over all key pairs -->
      <div v-for="(value, key) in jsonSummary" :key="key" class="json-item">
        <h4 class="json-key">{{ formatKey(key) }}</h4>
        <!-- Handle key being an object or array -->
        <JsonSummary v-if="isObject(value) || isArray(value)" :summary="value" />
        <!-- Handle key being a string -->
        <p v-else class="json-value">{{ value }}</p>
      </div>
    </div>

    <!-- Array support -->
    <div v-else-if="isArray(jsonSummary)" class="json-array">
      <ul class="json-array-list">
        <li v-for="(item, index) in jsonSummary" :key="index">
          <JsonSummary :summary="item" class="json-array-item"/>
        </li>
      </ul>
    </div>

    <!--String support -->
    <div v-else>
      <p class="json-primitive">{{ summary }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "JsonSummary",
  props: {
    summary: {
      type: [String, Object, Array],
      required: true,
    },
  },
  computed: {
    validJson() {
      if(this.isObject(this.summary) || this.isArray(this.summary)) {
        return true
      }
      try {
        JSON.parse(this.summary)
        return true
      } catch(_) {
        return false
      }
    },
    jsonSummary() {
      if(this.isObject(this.summary) || this.isArray(this.summary)) {
        return this.summary
      }
      try {
        return JSON.parse(this.summary)
      } catch(_) {
        return {}
      }
    },
  },
  methods: {
    isObject(value) {
      return typeof value === 'object' && value !== null && !Array.isArray(value);
    },
    isArray(value) {
      return Array.isArray(value);
    },
    formatKey(key) {
      // Convert snake_case or camelCase keys to Title Case
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .replace(/^\w/, c => c.toUpperCase());
    },
  },
}
</script>

<style lang="scss" scoped>
// Core variables and mixins
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.json-summary {
  line-height: 1.5;
}

.json-object,
.json-array {
  margin-left: 20px;
  border-left: 2px solid #ddd;
  padding-left: 10px;
  margin-bottom: 10px;
}

.json-array-list {
  list-style: none;
  padding: 0;
}

.json-item {
  margin-bottom: 1rem;
}

.json-key {
  font-weight: bold;
  color: $primary;
  margin-bottom: 10px;
}

.json-array-item {
  list-style-type: none;
  margin-left: 20px;
}
</style>
