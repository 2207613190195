<template>
  <div v-if="editJob" class="update-scribe-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateJob)">
        <section class="mb-2">
          <label>Title</label>
          <validation-provider
            v-slot="validationContext"
            name="Title"
            :rules="{ required: true }"
          >
            <b-form-input
              v-model="editJob.title"
              :state="getValidationState(validationContext)"
              class="mb-1"
              placeholder="Enter title"
            />

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
          <b-button variant="primary" type="submit">Update Scribe</b-button>
        </section>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>

export default {
  components: {},
  props: {
      job: {
          required: true,
          type: Object,
      },
  },
  data() {
    return {
        editJob: null,
    };
  },
  mounted() {
    this.editJob = { ...this.job }
  },
  methods: {
    async updateJob() {
        this.$emit('update', this.editJob)
    },
    getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
    },
  },
};
</script>

<style lang="scss">

</style>
