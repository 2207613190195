export default {
  async copyToClipboard(text) {
    // Ensure the navigator clipboard API is available. It isn't always
    if(navigator.clipboard) {
      return navigator.clipboard.writeText(text);
    }

    // Fallback method if clipboard not supported
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    return Promise.resolve()
  }
}
