





































import PurpleScribeService from "@/services/PurpleScribeService";
import helperService from "@/services/HelperService";
import {mapState} from "vuex";
import purpleScribeService from "@/services/PurpleScribeService";

export default {
  components: {},
  props: {
    jobID: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      summaryTypes: [],
      summaryType: '',
      loading: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
    }),
  },
  mounted() {
    this.summaryTypes = purpleScribeService.getSummaryTypes(this.user.enterprise_id);
    this.summaryType = "General"
  },
  methods: {
    async generateSummary() {
      this.loading = true
      try {
        const {data} = await PurpleScribeService.generateSummary(this.jobID, this.summaryType)
        helperService.showNotfySuccess(this.$toast, `${this.$options.filters.splitCamelCase(this.summaryType)} summary generated successfully`)
        this.$emit('summary-generated', data)
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, err.response.data.message);
      } finally {
        this.loading = false
      }
    },
  }
}
