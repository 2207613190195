const pdfMake = require('pdfmake/build/pdfmake');
const vfsFonts = require('pdfmake/build/vfs_fonts');

// Register the fonts with pdfmake
pdfMake.vfs = vfsFonts.pdfMake.vfs;

export default {
  download(documentDefinition, filename) {
    const pdf = pdfMake.createPdf(documentDefinition)
    return pdf.download(filename)
  }
}
