import axios from '@/libs/axios';

const baseUrl = '/scribe-summaries'

export default {
  update(id, rating) {
    return axios.put(`${baseUrl}/${id}`, {
      rating: rating,
    })
  },
  qAndAToText(summary) {
    let parsed = {}
    try {
      parsed = JSON.parse(summary)
    } catch(_) {
      return ''
    }

    let result = ''
    for(const question of parsed.questions) {
      result += `Q: ${question.question}\n`;
      result += `A: ${question.answer}\n\n`;
    }

    if(parsed.summary) {
      result += `Summary overview:\n${parsed.summary}`
    }

    return result
  },
  jsonToText(summary){
    // Helper methods
    function isObject(value) {
      return typeof value === 'object' && value !== null && !Array.isArray(value);
    }

    function isArray(value) {
      return Array.isArray(value);
    }

    function formatKey(key) {
      // Convert snake_case or camelCase keys to Title Case
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .replace(/^\w/, c => c.toUpperCase());
    }

    function parseSummary(summary) {
      if (isObject(summary) || isArray(summary)) {
        return summary;
      }
      try {
        return JSON.parse(summary);
      } catch (_) {
        return {};
      }
    }

    function generateSummaryText(summary, indent = 0) {
      let result = '';

      if (isObject(summary)) {
        for (const [key, value] of Object.entries(summary)) {
          const formattedKey = formatKey(key);
          if (isObject(value) || isArray(value)) {
            result += `${formattedKey}:\n\n`;
            result += generateSummaryText(value, indent + 2); // Recursively process nested objects/arrays
          } else {
            result += `${formattedKey}: ${value}\n\n`;
          }
        }
      } else if (isArray(summary)) {
        summary.forEach((item, index) => {
          result += `Item ${index + 1}:\n\n`;
          result += generateSummaryText(item, indent + 2); // Recursively process arrays
        });
      } else {
        result += `${summary}\n\n`; // Handle primitive values
      }

      return result;
    }

    // Parse the input and generate the summary
    const parsedSummary = parseSummary(summary);
    return generateSummaryText(parsedSummary).trim(); // Remove trailing whitespace
  }
}
