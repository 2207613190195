<template>
  <div>
    <section v-if="!isEditing">
      <p ref="summaryParagraph" class="summary mb-0">
        {{ summary }}
      </p>
      <button v-if="editable" class="btn btn-primary btn-sm my-1" @click="startEditing">Edit</button>
    </section>

    <section v-else>
      <textarea ref="summaryTextarea" v-model="editedSummary" class="form-control mb-1" rows="10"/>
      <button class="btn btn-success btn-sm mr-1" @click="save">Save</button>
      <button class="btn btn-warning btn-sm" @click="cancelEditing">Cancel</button>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    summary: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      editedSummary: this.summary,
      editorHeight: 0,
    };
  },
  methods: {
    startEditing() {
      // Get height of paragraph
      const pElement = this.$refs.summaryParagraph
      if(pElement) {
        this.editorHeight = pElement.offsetHeight + 30
      }

      this.isEditing = true;

      // Attempt to set editor height
      this.$nextTick(() => {
        if(this.editorHeight > 0) {
          this.$nextTick(() => {
            const editor = this.$refs.summaryTextarea
            if(editor) {
              editor.style.height = this.editorHeight + 'px'
            }
          })
        }
      })
    },
    cancelEditing() {
      this.isEditing = false;
      this.editedSummary = this.summary;
    },
    async save() {
      this.$emit('update', this.editedSummary)
      this.isEditing = false
    },
  },
};
</script>

<style scoped>
.summary {
  white-space: pre-line;
}
</style>
