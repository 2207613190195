<template>
  <div v-if="validJson" class="question-and-answer-summary">
    <!-- Questions -->
    <div v-if="jsonSummary.questions && jsonSummary.questions.length > 0">
      <div v-for="(question, index) in jsonSummary.questions" :key="index" class="mb-1">
        <h4 class="question">{{ question.question }}</h4>
        <p class="answer">{{ question.answer }}</p>
      </div>
    </div>

    <!-- Summaries -->
    <div v-if="jsonSummary.summary">
      <h4 class="question">Summary</h4>
      <p class="answer">{{ jsonSummary.summary }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "QAndASummary",
  props: {
    summary: {
      type: String,
      required: true,
    },
  },
  computed: {
    validJson() {
      try {
        JSON.parse(this.summary)
        return true
      } catch(_) {
        return false
      }
    },
    jsonSummary() {
      try {
        return JSON.parse(this.summary)
      } catch(_) {
        return {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// Core variables and mixins
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.question-and-answer-summary {
  .question {
    font-weight: bold;
    color: $primary;
    margin-bottom: 10px;
  }

  .answer {
    border-left: 2px solid #ddd;
    padding-left: 10px;
    margin-bottom: 10px;
  }
}
</style>
