<template>
  <div>
    <section v-if="!isEditing">
      <h4 class="mb-1">Transcript</h4>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td class="transcript">
                  {{ transcript }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button v-if="editable" class="btn btn-primary btn-sm my-1" @click="startEditing">Edit</button>
    </section>

    <section v-else>
      <textarea v-model="editedTranscript" class="form-control mb-1" rows="15"/>
      <button class="btn btn-success btn-sm mr-1" @click="saveTranscript">Save</button>
      <button class="btn btn-warning btn-sm" @click="cancelEditing">Cancel</button>
    </section>
  </div>
</template>

<script>
import helperService from "@/services/HelperService";
import PurpleScribeService from "@/services/PurpleScribeService";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    transcript: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      editedTranscript: this.transcript,
    };
  },
  methods: {
    startEditing() {
      this.isEditing = true;
    },
    cancelEditing() {
      this.isEditing = false;
      this.editedTranscript = this.transcript;
    },
    async saveTranscript() {
      this.isEditing = false;
      try {
        const {data} = await PurpleScribeService.updateTranscript(this.id, {
          transcript: this.editedTranscript.trim(),
        })
        this.$emit('update', data.transcript)
        this.$toast.success('Transcript was successfully updated.', {
          toastClassName: ['toast-std', 'success-toast'],
        })
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while updating the transcript. Please refresh and try again.');
      }
    },
  },
};
</script>

<style scoped>
.transcript {
  white-space: pre-line;
}
</style>
